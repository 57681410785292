import React, { useEffect, useState } from "react"
import api from "../../utils/api"
import { Link, navigate } from "gatsby"
import { Container, Form, Col, Row, Button, Alert } from "react-bootstrap"
import LoadingOverlay from "react-loading-overlay"

export default function Token(props) {
  const token = props.params.token

  const [formState, setFormState] = useState("verify")
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState("")
  const [success, setSuccess] = useState("")

  const verify = async e => {
    try {
      const result = await api().get(`/users/verify_login_token/${token}`)
      localStorage.setItem("token", result.data.token)
      localStorage.setItem("user", JSON.stringify(result.data.user))
      navigate("/")
    } catch (error) {
      //console.log(error)
      const message = error.response.data.message
      setLoading(false)
      setError(message)
      setTimeout(() => {
        navigate("/login")
      }, 2000)
    }
  }

  useEffect(() => {
    verify()
  }, [])
  return (
    <Container>
      <Row>
        <Col
          md={{ span: 6, offset: 3 }}
          style={{
            marginTop: "100px",
          }}
        >
          {error ? <Alert variant="danger">{error}</Alert> : ""}
          {success ? <Alert variant="success">{success}</Alert> : ""}
        </Col>
        <Col
          md={{ span: 6, offset: 3 }}
          style={{
            marginTop: "100px",
            display: `${formState == "verify" ? "block" : "none"}`,
          }}
        >
          Verifying...
        </Col>
        <Col
          md={{ span: 6, offset: 3 }}
          style={{
            marginTop: "100px",
            display: `${formState == "verified" ? "block" : "none"}`,
          }}
        >
          Login successful.
        </Col>
      </Row>
    </Container>
  )
}
